import React, { useState, useEffect } from 'react'
import "./CategoriesCheckBox.css"

const CategoriesCheckBox = ({ title, subcategories, onCategoryChange, onCategoryChange1,selectedCategoryFromCP }) => {
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(false);
  
  const [checkedItems, setCheckedItems] = useState(selectedCategoryFromCP??[]);


  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(()=>{
   if(selectedCategoryFromCP){
    setSelected(selectedCategoryFromCP &&selectedCategoryFromCP.includes(title)?true:false)
   }

  }, [selectedCategoryFromCP])


  const handleMainCategoryChange = (event) => {
    const isChecked = event.target.checked;
    setSelected(isChecked);
    if (isChecked) {
      let allSubcategories = subcategories.map((subcategory) => subcategory.name);
      if (allSubcategories.length !== 0) {
        let catList = allSubcategories;
        catList.push(title);
        onCategoryChange1(catList, isChecked);
      }
      else {
        onCategoryChange(title, isChecked);
      }
      setCheckedItems(allSubcategories);

    } else {
      setCheckedItems([]);
      const allSubcategories = subcategories.map((subcategory) => subcategory.name);
      if (allSubcategories.length !== 0) {
        let catList = allSubcategories;
        catList.push(title);
        onCategoryChange1(catList, isChecked);
      }
      else {
        onCategoryChange(title, isChecked);
      }


    }
  };

  const handleSubcategoryChange = (event, subcategory) => {

    const isChecked = event.target.checked;
    let updatedCheckedItems;
    if (isChecked) {
      updatedCheckedItems = [...checkedItems, subcategory];
    } else {
      updatedCheckedItems = checkedItems.filter((item) => item !== subcategory);
    }
    setCheckedItems(updatedCheckedItems);
    onCategoryChange(subcategory, event.target.checked);
  };



  return (
    <div  style={{ marginLeft: subcategories.length === 0 ? '17px' : '0' }}>
      <div className="category-filter" onClick={handleExpand}>

      {subcategories.length !== 0 && (
      <span className={`arrow ${expanded ? 'expanded' : ''}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          style={{ transform: expanded ? 'none' : 'rotate(270deg)' }}
        >
          <path d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z" fill="#CF9F47" />
        </svg>
      </span>
    )}

      
        <label className={`category-name ${selected ? 'selected-cat' : ''}`} style={{ paddingLeft: "6px" }}>
              <input
                type="checkbox"
                checked={selected}            
                 onChange={(event) => {
                  handleMainCategoryChange(event);
                }}
                className='custom-checkbox'
              /> {title}
            </label>
      
      
      </div>
      {expanded && (
        <div className="subcategory-list">
          {subcategories.map((subcategory, index) => (
            <label className='check-category' key={index}>
              <input
                type="checkbox"
              checked={checkedItems.includes(subcategory.name)}
                onChange={(event) => {
                  handleSubcategoryChange(event, subcategory.name);
                }}
                className='custom-checkbox'
              /> {subcategory.name}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
export default CategoriesCheckBox