import React, { useEffect, useState } from 'react';
import './App.css';
import { Footer, NavPage, ScrollTop } from "./Layouts/index";
import { Home, LoginPage, VerifyPage, RegisterPage, CheckEmailPage, SuccessPage, ResetPasswordPage, ProductsPage, ProductDetailsPage, CategoriesPage, ShoppingCartPage, CheckoutPage, SubCategoriesPage, AboutUs, Privacy, Portal, Profile } from "./Pages/index";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Loading } from './components/index';
import axios from "axios";
import faviconLogo from "./asset/images/gold-logo.png";

function App() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const [loading, setLoading] = useState(true);
  const url = "https://whatsapptest.inter-netsys.com";
  const apiMethod = "/api/method/geniusapi.www.api";
  const limit = 8;
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf("/") + 1;
  let pageName = pageUrl.substr(lastWordIndex);

  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [resultData, setResultData] = useState({});
  const [page_title, setPageTitle] = useState();
  const [data, setData] = useState([]);
  const [contentData, setContentData] = useState({});
  const [ItemData, setItemData] = useState({});
  const [CategoryData, setCategoryData] = useState({});

  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    if (!["", "/", "home"].includes(pageName)) {
      setLoading(false);
      return;
    }

    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        var apiUrl = `${url}${apiMethod}.content_by_page_name?name=${pageName}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        const result = data?.result || "";
        const content = data?.result?.content || {};
        const items = data?.result?.items || [];
        setPageTitle(response?.data?.result?.page_title || "");

        if (!pageUrl.includes("category/") && !pageUrl.includes("categories")) {
          const categories = data?.result?.categories || [];
          setCategoryData(categories);
        }

        setResultData(result);
        setContentData(content);
        setItemData(items);
        setLogos(data?.data?.logos || "");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (["", "/", "home"].includes(pageName)) {
      pageName = "home";
    }

    // fetchData(pageName);
  }, []);

  useEffect(() => {
   
    const path = new URL(window.location.href).pathname;
    const pathParts = path.split("/");

  
    if (pathParts.length >= 3 && pathParts[1] === "product" && pathParts[2]) {
      return; 
    }

    fetchWebsiteData(pageName);
  }, [pageName]);

  const fetchWebsiteData = async (pageName) => {
    setLoading(true);
    var headers = { "Content-Type": "application/json" };

    try {
      const response = await axios.get(
        `${url}/api/method/geniusapi.genius_api.doctype.pages.pages.page?name=${pageName}`,
        { headers: headers }
      );

      // setData(response?.data?.result?.content?.templates || []);
      setData(
        pageName === "products" 
          ? response?.data?.result
          : response?.data?.result?.content?.templates || []
      );
      // console.log("productsData",response?.data?.result || [])
      setPageTitle(response?.data?.result?.page_title || "");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = `${logos?.logo_favicon || faviconLogo}`;
      document.head.appendChild(link);
    };

    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ""} ${seoSettingsData?.separator || ""
        } ${innerPageData?.name || ""}`;
      const formattedTitle = mainTitle.trim() || "Genuis";
      if (formattedTitle === undefined) document.title = "Genuis";
      else {
        document.title = formattedTitle;
      }
    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);

  const isPortalRoute = window.location.pathname === "/portal";

  if (isPortalRoute) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/portal' element={<Portal />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        {loading ? (
          <div>
          <NavPage url={url} />
          <Loading></Loading>
          <Footer />
          </div>

        ) :
          (
            <>
              <ScrollTop />
              <NavPage url={url} />
              <Routes>
                <Route index element={<Home  data= {data} contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} />} />
                <Route path="/home" element={<Home data= {data} contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} />} />
                <Route path='/login' element={<LoginPage url={url} />} />
                <Route path='/register' element={<RegisterPage url={url} />} />
                <Route path='/checkEmail' element={<CheckEmailPage url={url} />} />
                <Route path='/verify' element={<VerifyPage />} />
                <Route path='/success' element={<SuccessPage />} />
                <Route path='/resetPassword' element={<ResetPasswordPage url={url} />} />
                <Route path='/products' element={<ProductsPage data={data} url={url} limit={limit} apiMethod={apiMethod}  />} />
                <Route path="/product/:id" element={<ProductDetailsPage url={url} apiMethod={apiMethod} serviceId={pageUrl.substr(lastWordIndex)} />} />
                <Route path="/categories" element={<CategoriesPage url={url} limit={limit} apiMethod={apiMethod} />} />
                <Route path="/category/:id" element={<SubCategoriesPage url={url} apiMethod={apiMethod} />} />
                <Route path="/shoppingCart" element={<ShoppingCartPage url={url} apiMethod={apiMethod} />} />
                <Route path="/checkout" element={<CheckoutPage url={url} apiMethod={apiMethod} />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/profile' element={<Profile url={url} />} />
              </Routes>
              <Footer />
            </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
