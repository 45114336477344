import React, { useState, useEffect, useRef ,useCallback} from 'react';
import _ from "lodash";
import "./ProductsPage.css"
import { CategoriesSlider, Filters } from '../../Features/ProductsPage'
import { Card, Paginations, Loading ,Title} from '../../components/index';
// import { Title } from "../../../../components";
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Percent from '../../asset/images/svg/AnimationPercent.svg';
import SortIcon from '../../asset/images/svg/sort.svg';
import SortMenu from '../../asset/images/svg/menu-sort.svg';
import { FormControlLabel, Checkbox } from '@mui/material';

export const ProductsPage = ({ url, limit, apiMethod ,data}) => {
  const location = useLocation();
  const categoryParam = new URLSearchParams(location.search).get("categories") ?? null;

  // ✅ إدارة الحالة (State Management)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsData, setProductsData] = useState([]);
  const [categoriesGlobal, setCategoriesGlobal] = useState([]);
  const [brandsGlobal, setBrandGlobal] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [selectedCategories, setCategories] = useState(categoryParam ? [categoryParam] : []);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [categoryParams, setCategoryParams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [checked, setChecked] = useState(false);
  const [orderBy, setOrderBy] = useState(""); 
  const [ordering, setOrdering] = useState("asc");    
  const [showOrderByMenu, setShowOrderByMenu] = useState(false);
  const [showOrderingMenu, setShowOrderingMenu] = useState(false);

const allowedOrderBy = {
  "tabWebsite Item": ["web_item_name", "item_name", "item_code", "ranking"],
  "tabWebsite Item Prices": ["price_list_rate", "before_discount_price_list_rate", "discount_amount", "start_date", "end_date"]
};

  // ✅ مراجع لتتبع القيم السابقة
  const prevSelectedBrandsRef = useRef([]);
  const prevSearchTermRef = useRef("");
  const prevSelectedCategoriesRef = useRef([]);
  const prevMinPriceRef = useRef(0);
  const prevMaxPriceRef = useRef(10000);


  // ✅ عند تحميل البيانات الأولية من `data`
  useEffect(() => {
    if (data?.content) {
      setCategoriesGlobal(data?.content?.categories);
      setBrandGlobal(data?.content?.brands);
      setProductsData(data?.content?.products?.items);
      setTotalPages(1);
      setTotalProducts(data?.content?.products?.items?.length);
      setLoading(false);
    }
  }, [data?.content]);

  // ✅ تحديث الفئات عند تغير `categoryParam`
  useEffect(() => {
    if (categoryParam) {
      setCategoryParams(categoryParam);
    }
  }, [categoryParam]);

  // ✅ تحديث البيانات عند تغيير `selectedBrands`
  useEffect(() => {
    if (JSON.stringify(selectedBrands) !== JSON.stringify(prevSelectedBrandsRef.current)) {
      fetchDataByPage(currentPage);
      prevSelectedBrandsRef.current = selectedBrands;
    }
  }, [selectedBrands]);

  // ✅ تحديث البيانات عند تغيير `searchTerm`
  useEffect(() => {
    if (searchTerm && searchTerm !== prevSearchTermRef.current) {
      fetchDataByPage(currentPage);
      prevSearchTermRef.current = searchTerm;
    }
  }, [searchTerm]);

  // ✅ تحديث البيانات عند تغيير `selectedCategories` أو `currentPage`
  useEffect(() => {
    if (JSON.stringify(selectedCategories) !== JSON.stringify(prevSelectedCategoriesRef.current)) {
      prevSelectedCategoriesRef.current = selectedCategories;
      fetchDataByPage(currentPage, selectedCategories);
    }
  }, [selectedCategories, currentPage]);

  // ✅ تحديث البيانات عند تغيير `minPrice` أو `maxPrice`
  useEffect(() => {
    if (minPrice !== prevMinPriceRef.current || maxPrice !== prevMaxPriceRef.current) {
      fetchDataByPage(currentPage);
      prevMinPriceRef.current = minPrice;
      prevMaxPriceRef.current = maxPrice;
    }
  }, [minPrice, maxPrice]);

  const fetchDataByPage = async (page, updatedCategories = selectedCategories, orderByParam = orderBy, orderingParam = ordering) => {
    const startIndex = (page - 1) * limit;
    const searchParam = searchTerm ? `&search=${searchTerm}` : '';
    const minPriceParam = minPrice ? `&min_price=${minPrice}` : '';
    const maxPriceParam = maxPrice ? `&max_price=${maxPrice}` : '';

    let categoriesParam = selectedCategories.length > 0 ? `&item_groups=${selectedCategories.join(',')}` : '';
    let brandsParam = selectedBrands.length > 0 ? `&brands=${selectedBrands.join(',')}` : '';
    const orderByParamStr = orderByParam ? `&order_by=${orderByParam}` : "";
    const orderingParamStr = orderingParam ? `&ordering=${orderingParam}` : "";

    const apiURL = `${url}${apiMethod}.products?start=${startIndex}&limit=${limit}${searchParam}${minPriceParam}${maxPriceParam}${categoriesParam}${brandsParam}${orderByParamStr}${orderingParamStr}`;

    try {
        const response = await axios.get(apiURL);
        setLoading(false);
        setProductsData(response?.data?.result?.items || []);
        setTotalPages(response?.data?.result?.items?.pagination?.metadata?.total_pages || 1);
        setTotalProducts(response?.data?.result?.items?.pagination?.metadata?.total_data || 0);
    } catch (error) {
        console.error("❌ Error fetching products:", error);
    }
};

  // 🟢 تشغيل fetchDataByPage عند تغيير `orderBy` أو `ordering`
  useEffect(() => {
    fetchDataByPage(1, selectedCategories, orderBy, ordering);
  }, [orderBy, ordering]);

  // 🟢 دالة اختيار order_by
  const handleOrderByChange = (newOrderBy) => {
    setOrderBy(newOrderBy);
    setShowOrderByMenu(false); // إغلاق القائمة بعد الاختيار
  };

  // 🟢 دالة اختيار ordering (تصاعدي/تنازلي)
  const handleOrderingChange = (newOrdering) => {
    setOrdering(newOrdering);
    setShowOrderingMenu(false); // إغلاق القائمة بعد الاختيار
  };


  
  

  // ✅ إدارة التفاعل مع واجهة المستخدم (UI Handlers)
  const handlePageChange = (page) => setCurrentPage(page);
  const handleSearch = (searchTerm) => setSearchTerm(searchTerm);
  const handlePrice = (minPrice, maxPrice) => {
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
  };
  const handleSidebar = () => setActiveSidebar(!activeSidebar);

  const handleChangeBrand = (e) => {
    const brandName = e.target.value;
    const isChecked = e.target.checked;
    setSelectedBrands((prev) =>
      isChecked ? [...prev, brandName] : prev.filter((brand) => brand !== brandName)
    );
  };

  const handleChangeCategory = (value, checked) => {
    setCategories((prevCategories) =>
      checked ? [...prevCategories, value] : prevCategories.filter((category) => category !== value)
    );
  };

  const handleChangeCategoryBulk = (values, checked) => {
    setCategories((prevCategories) => {
      let updatedCategories = [...prevCategories];
      if (checked) {
        values.forEach((name) => {
          if (!prevCategories.includes(name)) updatedCategories.push(name);
        });
      } else {
        updatedCategories = updatedCategories.filter((name) => !values.includes(name));
      }
      return updatedCategories;
    });
  };

  // ✅ العروض الخاصة
  const handleSpecialOffersToggle = (event) => {
    console.log("Special Offers:", event.target.checked);
  };


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSortClick = () => {
    setOrdering((prevOrdering) => (prevOrdering === "asc" ? "desc" : "asc"));
};

  

  return (
    <div>
      {loading == true ? (
        <Loading></Loading>
      ) : (
        <div className="products">
          <div
            className={`sidebar-responsive-container ${
              activeSidebar ? "active" : ""
            }`}
          >
            <div
              className="sidebar-responsive-overlay"
              onClick={handleSidebar}
            ></div>
            <div className="sidebar-responsive">
              <Filters
                url={url}
                onSearch={handleSearch}
                onPriceFilter={handlePrice}
                CategoriesFilter={categoriesGlobal}
                onCategoryChange={handleChangeCategory}
                onCategoryChange1={handleChangeCategoryBulk}
                BrandsFilter={brandsGlobal}
                onChangeBrand={handleChangeBrand}
                categoryParams={selectedCategories}
              />
            </div>
          </div>
          <Container>
            <div className="show-filters-container">
              <div className="show-filters" onClick={handleSidebar}>
                <h1 className="show-filters-title">Show Filters</h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  width="256"
                  height="256"
                  viewBox="0 0 256 256"
                  xmlSpace="preserve"
                >
                  <g
                    style={{
                      stroke: "none",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 10,
                      fill: "none",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                  >
                    <path
                      d="M 15.898 90 c -1.657 0 -3 -1.343 -3 -3 V 54.88 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 V 87 C 18.898 88.657 17.555 90 15.898 90 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 74.103 60.069 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 54.069 C 77.103 58.727 75.76 60.069 74.103 60.069 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 45 90 c -1.657 0 -3 -1.343 -3 -3 V 28.319 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 V 87 C 48 88.657 46.657 90 45 90 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 45 31.319 c -5.914 0 -10.726 -4.812 -10.726 -10.726 S 39.086 9.868 45 9.868 s 10.726 4.812 10.726 10.726 S 50.914 31.319 45 31.319 z M 45 15.868 c -2.605 0 -4.726 2.12 -4.726 4.726 s 2.12 4.726 4.726 4.726 s 4.726 -2.12 4.726 -4.726 S 47.605 15.868 45 15.868 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 15.898 57.88 c -5.915 0 -10.726 -4.812 -10.726 -10.726 c 0 -5.915 4.812 -10.726 10.726 -10.726 c 5.914 0 10.726 4.812 10.726 10.726 C 26.624 53.068 21.812 57.88 15.898 57.88 z M 15.898 42.428 c -2.606 0 -4.726 2.12 -4.726 4.726 c 0 2.605 2.12 4.726 4.726 4.726 c 2.605 0 4.726 -2.12 4.726 -4.726 C 20.624 44.548 18.503 42.428 15.898 42.428 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 74.103 75.521 c -5.915 0 -10.727 -4.812 -10.727 -10.727 c 0 -5.914 4.812 -10.726 10.727 -10.726 c 5.914 0 10.726 4.812 10.726 10.726 C 84.828 70.71 80.017 75.521 74.103 75.521 z M 74.103 60.069 c -2.606 0 -4.727 2.12 -4.727 4.726 c 0 2.606 2.12 4.727 4.727 4.727 c 2.605 0 4.726 -2.12 4.726 -4.727 C 78.828 62.189 76.708 60.069 74.103 60.069 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 15.898 42.428 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 36.428 C 18.898 41.085 17.555 42.428 15.898 42.428 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 45 15.868 c -1.657 0 -3 -1.343 -3 -3 V 3 c 0 -1.657 1.343 -3 3 -3 c 1.657 0 3 1.343 3 3 v 9.868 C 48 14.524 46.657 15.868 45 15.868 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                    <path
                      d="M 74.103 90 c -1.657 0 -3 -1.343 -3 -3 V 72.521 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 V 87 C 77.103 88.657 75.76 90 74.103 90 z"
                      style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(0,0,0)",
                        fillRule: "nonzero",
                        opacity: 1,
                      }}
                    />
                  </g>
                </svg>
              </div>
            </div>
            <Row>
              <div className="col-md-4 col-lg-2 col-12 filters-big-screen">
                <Filters
                  url={url}
                  onSearch={handleSearch}
                  onPriceFilter={handlePrice}
                  CategoriesFilter={categoriesGlobal}
                  onCategoryChange={handleChangeCategory}
                  onCategoryChange1={handleChangeCategoryBulk}
                  BrandsFilter={brandsGlobal}
                  onChangeBrand={handleChangeBrand}
                  categoryParams={selectedCategories}
                />
              </div>
              <div className="col-lg-10 col-12 col-product">
                {categoryParam ? (
                  <div className="banner">
                    <img
                      src={require("../../asset/images/Products/banner3.jpg")}
                      alt="Banner"
                      className="banner-image"
                    />
                  </div>
                ) : categoriesGlobal && categoriesGlobal.length > 0 ? (
                  <CategoriesSlider
                    categories={categoriesGlobal}
                    url={url}
                    onCategoryChange={handleChangeCategory}
                  />
                ) : (
                  <div className="d-none"></div>
                )}

                <div className="product-list-filters">
                  <Title title={"Featured Products"} para={""} />
                  <div className="filters-display">
                    <div className="offers-div">
                      {/* <div className="circle-container">
                      <div className="circle">
                        <span className="percent">%</span>
                      </div>
                    </div> */}
                      <img src={Percent} alt="Percent Icon" className="spin" />
                      <label
                        className="form-check-label offers-text"
                        htmlFor="specialOffers"
                        style={{ marginRight: "8px" }} // Optional for spacing
                      >
                        Offers
                      </label>

                      <div>
                        <label className="custom-checkbox-container">
                          <input
                            type="checkbox"
                            onChange={handleChange}
                            className="custom-checkbox"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {/* Special Offers */}
                      {/* <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="specialOffers"
                          onChange={handleSpecialOffersToggle}
                        />
                      </div> */}
                    </div>
                    <div className="seperator"></div>
                    {/* <div className="sort-div" onClick={handleSortClick}>
                      <img src={SortIcon}></img>
                      <span className="sort-text">Sort: Price</span>
                      <img src={SortMenu}></img>
                    </div> */}
                    <div className="sort-container">
                      {/* Order By Dropdown Trigger */}
                      <div
                        className="sort-header"
                        // onClick={() =>
                        //   setIsOrderByDropdownOpen(!isOrderByDropdownOpen)
                        // }
                      >
                        <img src={SortIcon} alt="Sort Icon" />
                        <span className="sort-text" onClick={() => setShowOrderByMenu(!showOrderByMenu)}>
                        Sort: {orderBy || "Select"}
                        </span>
                      </div>

                      {/* Ordering Dropdown Trigger */}
                      <img
                        src={SortMenu}
                        alt="Sort Menu"
                        className="sort-menu-icon"
                        onClick={() => setShowOrderingMenu(!showOrderingMenu)}
                      />

                      {/* Order By Dropdown */}
                      {showOrderByMenu && (
                        <div className="sort-dropdown">
                          <div className="sort-section">
                            <span className="sort-label">Sort by:</span>
                            {Object.entries(allowedOrderBy).map(
                              ([category, options]) => (
                                <div key={category} className="sort-group">
                                  <strong>{category}</strong>
                                  {options.map((option) => (
                                    <div
                                      key={option}
                                      className={`sort-option ${
                                        orderBy === option ? "selected" : ""
                                      }`}
                                      onClick={() => handleOrderByChange(option)}
                                    >
                                      {option.replace(/_/g, " ")}
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {/* Ordering Dropdown (Ascending/Descending) */}
                      {showOrderingMenu && (
                        <div className="sort-dropdown ordering-dropdown">
                          <div className="sort-section">
                            <span className="sort-label">Order:</span>
                            <div
                              className={`sort-option ${
                                ordering === "asc" ? "selected" : ""
                              }`}
                              onClick={() => handleOrderingChange("asc")}
                            >
                              Ascending ↑
                            </div>
                            <div
                              className={`sort-option ${
                                ordering === "desc" ? "selected" : ""
                              }`}
                              onClick={() => handleOrderingChange("desc")}
                            >
                              Descending ↓
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="products-list">
                  {productsData && productsData.length === 0 ? (
                    <center>
                      {" "}
                      <p>No Products</p>{" "}
                    </center>
                  ) : (
                    <div className="card-grid">
                      {productsData &&
                        productsData.map((item, index) => (
                          <Card
                            key={item.web_item_name}
                            title={item.item_group}
                            para={item.web_item_name}
                            image={url + item.website_image}
                            pricebefore={item.before_discount_price_list_rate}
                            priceafter={item.discounted_price_list_rate}
                            sale={item.sale}
                            name={item.name}
                          />
                        ))}
                    </div>
                  )}
                </div>

                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "60px" }}
                >
                  <Paginations
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>

                {/* {totalProducts !== 0 && (
                                    <div className='total'>
                                        Total: {totalProducts}
                                    </div>
                                )} */}
              </div>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
}


export default ProductsPage;