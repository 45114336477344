import React, { useEffect, useState } from 'react';
import "./Address.css";
import { NewAddress } from '../../index';
import axios from "axios";
import Cookies from "js-cookie";

const Address = ({ url, setLoadingProfile }) => {
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);

  const handleAddNewAddressClick = () => {
    setSelectedAddress(null);
    setShowNewAddress(true);
  };

  const handleEditAddressClick = (address) => {
    setSelectedAddress(address); 
    setShowNewAddress(true);
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        setLoadingProfile(true)
        const headers = {
          "Authorization": `token ${Cookies.get("token")}`
        };
        setLoadingProfile(true);
        const response = await axios.get(`${url}/api/method/geniusapi.www.api.addresses`, 
          {headers}
        );
        if (response.data.result) {
          setAddresses(response.data.result);
          setLoadingProfile(false);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchAddresses();
  }, [url]);

  return (
    <div>
      <div className='profile-address-title'>Address</div>
      <div className='profile-address-para'>You can show your address</div>
      <div className='profile-address-info-container'>
        {addresses.length > 0 && (
          addresses.map((item) => (
            <div key={item.id} className='profile-address-info'>
              <div className='profile-address-name'>{item.links[0].link_name}</div>
              <div className='profile-address-desc'>{item.address_title} {item.address_line2 ? item.address_line2 : ""}</div>
              <button className='add-address' onClick={() => handleEditAddressClick(item)}>Edit Address</button>
              <button className='remove-address'>Remove</button>
            </div>
          ))
        )}

        {!showNewAddress && (
          <div className='new-address' onClick={handleAddNewAddressClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 13 12" fill="none">
              <path d="M6.36659 1L6.35352 11" stroke="#101728" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1 6H11.6897" stroke="#101728" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <h1 className='new-address-title'>Add New Address</h1>
          </div>
        )}

        {showNewAddress && (
          <NewAddress 
            url={url}
            onClose={() => setShowNewAddress(false)} 
            addressData={selectedAddress} 
          />
        )}
      </div>
    </div>
  );
};

export default Address;
