import React, { useState } from 'react'
import "./Profile.css"
import { Col, Container, Row } from 'react-bootstrap'
import { ProfileTap, Address, OrderHistory, MyProfile } from '../../Features/Profile'
import { CircularProgress } from "@mui/material";

const Profile = ({ url }) => {

    const [activeTab, setActiveTab] = useState("profile");
    const [loadingProfile, setLoadingProfile] = useState(false);

  return (
    <Container className='margin-top'> 
        <Row>
            <Col lg={3} className="tabs-container">
                <ProfileTap 
                    text={"Make changes to your account"} 
                    title={"My Profile"} 
                    svg={
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 14 18" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98724 11.7886C3.76422 11.7886 1.01184 12.2759 1.01184 14.2275C1.01184 16.1791 3.74676 16.6838 6.98724 16.6838C10.2103 16.6838 12.9618 16.1957 12.9618 14.2449C12.9618 12.2941 10.2277 11.7886 6.98724 11.7886Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98729 9.00492C9.10237 9.00492 10.8167 7.28984 10.8167 5.17476C10.8167 3.05968 9.10237 1.3454 6.98729 1.3454C4.87221 1.3454 3.15713 3.05968 3.15713 5.17476C3.14998 7.2827 4.85316 8.99778 6.9603 9.00492H6.98729Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    }   
                    onClick={() => setActiveTab("profile")}   
                    isActive={activeTab === "profile"}
                />   
                <ProfileTap 
                    text={"Make changes to your account"} 
                    title={"Order history"} 
                    svg={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none">
                            <path d="M6.25 14.375H11.25M6.25 11.25H13.75M6.25 8.125H13.75M6.875 3.125H4.375V18.125H15.625V3.125H13.125M6.875 1.875H13.125L12.3438 4.375H7.65625L6.875 1.875Z" stroke="black" stroke-linejoin="round"/>
                        </svg>
                    }      
                    onClick={() => setActiveTab("orderHistory")}
                    isActive={activeTab === "orderHistory"}
                />  
                <ProfileTap 
                    text={"Make changes to your account"} 
                    title={"Address"} 
                    svg={
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2059 17.4742C11.1643 16.6045 12.0513 15.6592 12.8584 14.6475C14.5584 12.5117 15.5925 10.4059 15.6625 8.53335C15.6902 7.77235 15.5643 7.01357 15.2922 6.30234C15.02 5.59111 14.6074 4.94202 14.0788 4.39384C13.5502 3.84567 12.9166 3.40966 12.2157 3.11186C11.5148 2.81405 10.7611 2.66057 9.99962 2.66057C9.23812 2.66057 8.48442 2.81405 7.78356 3.11186C7.08269 3.40966 6.44905 3.84567 5.92046 4.39384C5.39188 4.94202 4.9792 5.59111 4.70709 6.30234C4.43498 7.01357 4.30901 7.77235 4.33671 8.53335C4.40754 10.4059 5.44254 12.5117 7.14171 14.6475C7.94876 15.6592 8.83578 16.6045 9.79421 17.4742C9.88643 17.5575 9.95504 17.6181 10 17.6559L10.2059 17.4742ZM9.38504 18.445C9.38504 18.445 3.33337 13.3484 3.33337 8.33335C3.33337 6.56524 4.03575 4.86955 5.286 3.61931C6.53624 2.36907 8.23193 1.66669 10 1.66669C11.7682 1.66669 13.4638 2.36907 14.7141 3.61931C15.9643 4.86955 16.6667 6.56524 16.6667 8.33335C16.6667 13.3484 10.615 18.445 10.615 18.445C10.2784 18.755 9.72421 18.7517 9.38504 18.445ZM10 10.6667C10.6189 10.6667 11.2124 10.4209 11.65 9.98327C12.0875 9.54569 12.3334 8.95219 12.3334 8.33335C12.3334 7.71452 12.0875 7.12102 11.65 6.68344C11.2124 6.24585 10.6189 6.00002 10 6.00002C9.3812 6.00002 8.78771 6.24585 8.35012 6.68344C7.91254 7.12102 7.66671 7.71452 7.66671 8.33335C7.66671 8.95219 7.91254 9.54569 8.35012 9.98327C8.78771 10.4209 9.3812 10.6667 10 10.6667ZM10 11.6667C9.11599 11.6667 8.26814 11.3155 7.64302 10.6904C7.0179 10.0653 6.66671 9.21741 6.66671 8.33335C6.66671 7.4493 7.0179 6.60145 7.64302 5.97633C8.26814 5.35121 9.11599 5.00002 10 5.00002C10.8841 5.00002 11.7319 5.35121 12.3571 5.97633C12.9822 6.60145 13.3334 7.4493 13.3334 8.33335C13.3334 9.21741 12.9822 10.0653 12.3571 10.6904C11.7319 11.3155 10.8841 11.6667 10 11.6667Z" fill="black"/>
                        </svg>
                    }   
                    onClick={() => setActiveTab("address")}   
                    isActive={activeTab === "address"}
                />  
            </Col>
            <Col lg={9} style={{ paddingLeft: '30px', position: 'relative'}} className='tabs-content-container'>
                {
                    loadingProfile && (
                        <>
                            <div className='loading-profile-spinner'></div>
                            <div className='spinner-container'>
                                <CircularProgress 
                                    sx={{ 
                                        color: "#101728"
                                    }}
                                />
                            </div>
                        </> 
                    ) 
                } 
                <div style={{ display: activeTab === "profile" ? "block" : "none" }}>
                    <MyProfile url={url} setLoadingProfile={setLoadingProfile}  />
                </div>
                <div style={{ display: activeTab === "orderHistory" ? "block" : "none" }}>
                    <OrderHistory url={url} setLoadingProfile={setLoadingProfile} />
                </div>
                <div style={{ display: activeTab === "address" ? "block" : "none" }}>
                    <Address url={url} setLoadingProfile={setLoadingProfile} />
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Profile