import React from 'react'
import "./ProductsCategory.css"
import { Col, Row } from 'react-bootstrap'

const ProductsCategory = ({ title, image, color, href, handleClick ,status,category,selectedCategories}) => {
  const handleLinkClick = (event) => {
    if (handleClick) {
      event.preventDefault(); // منع السلوك الافتراضي للرابط
      handleClick(category); // تمرير الفئة إلى دالة التصفية
    }
  };

    // Check if the category is selected
    const isSelected = selectedCategories.includes(category);

  // Display only the first two words of the title
  const formattedTitle = title
    .split(" ")
    .slice(0, 2) // Keep only the first two words
    .map((word, index) => (
      <React.Fragment key={index}>
        {word}
        {index < 1 && <br />} {/* Add a line break after the first word */}
      </React.Fragment>
    ));

  return (
    <a href={href} onClick={handleLinkClick} className='product-category-container'>
      {/* <div className="products-category" style={{ backgroundColor: color }}>
        <div className="products-category-title">{title}</div>
        <img src={image} alt="Product" className="image-product-category" />
      </div> */}
      <div className='category-div'>
        <div className={`category-icon-div ${isSelected ? 'category-selected' : ''}`} style={{ backgroundColor: color }}>
           <img src={image} className='icon-product-category'/>
        </div>
        <div className='product-category-name'>{formattedTitle}</div>
        {status === "HOT" && <div className="hot-status">{status}</div>}
        {status === "Trendy" && <div className="trendy-status">{status}</div>}
      </div>
    </a>
  );
};

export default ProductsCategory