import React, { useState, useEffect } from 'react';
import "./Portal.css";
import logoG from "../../asset/images/portal/logo1.svg";
import logoM from "../../asset/images/portal/logo2.svg";

const Portal = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 970) {
        setIsHovered(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); 

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="portal-container">
      <div className={`right ${isHovered ? 'expanded' : ''}`}>
        <div className='portal-content'>
          <div className='portal-logo-container'>
            <img src={logoM} className='logo-m' alt="Logo M" />
          </div>  
          <p className="portal-para">Sale 20% for all products</p>
          <h1 className="portal-title"><span>Design</span> Services</h1>
          <div className="portal-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, sum dolor sit amet, cons ipsum dolor sit amet</div>
          <a className='portal-link'>Choose services</a>
          <div className='portal-overlay'></div>
        </div>
      </div>
      <div 
        className="left"
        onMouseEnter={() => window.innerWidth >= 970 && setIsHovered(true)}
        onMouseLeave={() => window.innerWidth >= 970 && setIsHovered(false)}
      >
        <div className='portal-content'>
          <div className='portal-logo-container'>
            <img src={logoM} className='logo-m' alt="Logo M" />
            <img src={logoG} className='logo-g' alt="Logo G" />
          </div>        
          <p className="portal-para">Sale 20% for all products</p>
          <h1 className="portal-title"><span>Montessori</span> Store</h1>
          <div className="portal-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, sum dolor sit amet, cons ipsum dolor sit amet</div>
          <a className='portal-link'>Shop Now</a>
          <div className='portal-overlay'></div>
        </div>
      </div>
    </div>
  );
};

export default Portal;
